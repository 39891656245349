<template>
  <div class="page-title">{{ content.updateYourProfile }}</div>
  <div class="profile-update-container grid grid-cols-2 gap-x-0 sm:gap-x-10 mt-12">
    <div class="col-span-2 lg:col-span-1">
      <div class="flex flex-col sm:flex-row mt-8">
        <label for="name" class="input-label mb-2 sm:mb-0 flex items-center">
          {{ content.name }}
        </label>
        <input
          type="text"
          class="form-input"
          v-model="user.name"
          id="name"
          :placeholder="content.name"
        />
      </div>
    </div>
    <div class="col-span-2 lg:col-span-1 relative">
      <div class="flex flex-col sm:flex-row mt-7">
        <label for="file" class="input-label mb-2 sm:mb-0 flex items-center">
          {{ content.profileImage }}
        </label>
        <label for="file" class="w-full upload-input">
          <input type="file" id="file" class="hidden" @change="updateImage" />
          <div class="flex justify-between w-full border-with-radius ps-4">
            <div class="flex items-center ms-4">{{ content.uploadFile }}</div>
            <div class="upload-select lora-bold">{{ content.select }}</div>
          </div>
        </label>
      </div>
      <img
        :src="showImage ?? user.profile ?? '/static/images/icons/user-icon.svg'"
        class="profile-image"
      />
    </div>
    <div class="col-span-2 lg:col-span-1">
      <div class="flex flex-col sm:flex-row mt-8">
        <label for="email" class="input-label flex mb-2 sm:mb-0 items-center">
          {{ content.email }}
        </label>
        <input
          type="text"
          class="form-input"
          v-model="user.email"
          id="email"
          placeholder="example@companyname.com"
        />
      </div>
    </div>
    <div class="col-span-2 lg:col-span-1">
      <div class="flex flex-col sm:flex-row mt-8">
        <label for="phone" class="input-label mb-2 sm:mb-0 flex items-center">
          {{ content.phone }}
        </label>
        <input
          type="text"
          class="form-input"
          v-model="user.phone"
          id="phone"
          placeholder="07xx xxx xxx"
        />
      </div>
    </div>
    <div class="col-span-2 lg:col-span-1">
      <div class="flex flex-col sm:flex-row mt-8">
        <label for="dob" class="input-label flex mb-2 sm:mb-0 items-center">
          {{ content.dob }}
        </label>
        <input type="date" class="form-input" v-model="user.dob" id="dob" />
      </div>
    </div>
    <div class="col-span-2 lg:col-span-1">
      <div class="flex flex-col sm:flex-row mt-8">
        <label for="gender" class="input-label mb-2 sm:mb-0 flex items-center">
          {{ content.gender }}
        </label>
        <select id="gender" class="form-input bg-white" v-model="user.gender">
          <option disabled selected :value="null">
            {{ content.chooseGender }}
          </option>
          <option value="male">{{ content.male }}</option>
          <option value="female">{{ content.female }}</option>
          <option :value="null">{{ content.preferNotToSay }}</option>
        </select>
      </div>
    </div>
    <div class="col-span-2 lg:col-span-1">
      <div class="flex flex-col sm:flex-row mt-8">
        <!-- 
        <input
          type="text"
          class="form-input"
          v-model="user.city"
          id="city"
          placeholder="city"
        />
       -->
        <label for="city" class="input-label mb-2 sm:mb-0 flex items-center">
          {{ content.city }}
        </label>
        <select id="city" class="form-input bg-white" v-model="userCity">
          <option disabled selected :value="null">City</option>
          <option v-for="city in cities" :key="city.id" :value="city.id">
            {{ city.name }}
          </option>
        </select>
      </div>
    </div>
    <!-- <div class="col-span-2 lg:col-span-1">
      <div class="flex flex-col sm:flex-row mt-8">
        <label
          for="district"
          class="input-label mb-2 sm:mb-0 flex items-center"
        >
          {{ content.district }}
        </label>
        <input
          type="text"
          class="form-input"
          v-model="user.district"
          id="district"
          placeholder="district"
        />
      </div>
    </div> -->
    <div class="col-span-2">
      <div class="flex flex-col sm:flex-row mt-8">
        <label for="address" class="input-label mb-2 sm:mb-0 flex mt-2.5">
          {{ content.address }}
        </label>
        <textarea
          type="text"
          class="form-input"
          v-model="user.address"
          id="address"
          :placeholder="content.address"
        />
      </div>
    </div>
  </div>
  <div class="flex justify-end my-8">
    <button
      class="header-btn hover:text-bluePrimary text-white bg-bluePrimary lora-bold whitespace-nowrap hover:bg-blueSecondary duration-300"
      @click="updateProfile"
    >
      {{ content.updateInformation }}
    </button>
  </div>
  <!-- <hr style="color: #f2715b22" class="my-8" />
  <div class="page-title">
    {{ content.moreOptions }}
  </div>
  <button
    class="
      arrow-more-btn
      flex
      justify-between
      items-center
      mt-8
      mb-16
      w-max-content
    "
    @click="deactivateAccount"
  >
    {{ content.deactivateAccount }}
    <i
      class="
        fas
        text-size-12
        fa-chevron-right
        btn-arrow-icon
        text-base
        transition-all
        delay-75
        ms-8
      "
    ></i>
  </button> -->
</template>

<script>
import addPhoto from "../../../imageUpload";

export default {
  inject: ["content"],
  data() {
    return {
      image: null,
      showImage: null,
      error: null,
      userCity: null,
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    cities() {
      return this.$store.getters["cities/cities"];
    },
    // userCity(){
    //   return this.user.city?.id ?? null;
    // }
  },
  watch: {
    user() {
      if (this.user) {
        this.userCity = this.user.city?.id;
      }
    },
  },
  mounted() {
    if (this.user) {
      this.userCity = this.user.city?.id;
    }
  },
  methods: {
    updateImage(event) {
      let image = event.target.files[0];

      if (image.size / 1024 > 700) {
        this.error = this.content.largeFile;
        return;
      }

      if (image["type"].split("/")[0] != "image") {
        this.error = this.content.fileType;
        return;
      }

      let reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = async (e) => {
        this.showImage = e.target.result;
        this.$emit("done", image);
      };

      this.image = image;
    },
    async updateProfile() {
      try {
        let link = null;
        if (this.image) link = await addPhoto(this.image);

        let input = {
          name: this.user.name,
          email: this.user.email,
        };

        if (this.userCity) input.city = this.userCity;
        if (this.user.phone) input.phone = this.user.phone;
        if (this.user.address) input.address = this.user.address;
        if (this.user.dob) input.dob = this.user.dob;
        if (this.user.gender) input.gender = this.user.gender;
        if (link) input.profile = link;

        await this.$store.dispatch("user/update", input);
      } catch (error) {
        console.log(error);
      }
    },
    // async deactivateAccount() {
    //   try {
    //     await this.$store.dispatch("auth/deactivate");
    //     this.$router.replace("/");
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
  },
};
</script>
